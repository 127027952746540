<template>
  <div class="process">
    <div class="box-card" style="padding: 20px;">
      <div>
        <div style="margin-bottom: 10px;margin-top: -10px;float: left;" class="box-title">开收盘记录</div>
        <div style="float: right;margin-top: -23px;">
          <el-form>
            <el-form-item label="开收盘状态：" style="float: left;">
              <el-select v-model="data.valueShare" style="width: 200px;margin: 0 10px" placeholder="请选择关键字" clearable
                size="mini">
                <el-option v-for="item in data.valueOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开收盘动作:" style="float: left;">
              <el-select v-model="data.movement" style="width: 200px;margin: 0 10px" placeholder="请选择关键字" clearable
                size="mini">
                <el-option v-for="item in data.valueMovement" :key="item.type" :label="item.name" :value="item.type"/>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="mini" style="margin-left: 10px;" @click="searchFor">搜索</el-button>
            </el-form-item>

          </el-form>
        </div>
      </div>
      
      <div style="margin: 10px 0;clear: both;">
        <div style="margin-top:20px;">
          <el-table :data="data.tableData" style="width: 100%"
            :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
            <el-table-column prop="id" label="ID" />
            <el-table-column prop="type" label="动作">
              <template #default="scope">
                <div v-if="scope.row.type == 1"><span>收盘</span></div>
                <div v-else><span>开盘</span></div>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="执行状态" />
            <el-table-column prop="failureRemarks" label=" 备注" />
            <el-table-column prop="date" label="执行时间" />
            <el-table-column label=" 操作">
              <template #default="scope">
                <div v-if="scope.row.type == 1">
                  <el-button class="btn-primary" v-if="scope.row.state == '失败'" type="text" 
                    @click="forcedClosing(scope.row)">强制收盘</el-button>
                  <el-button class="btn-primary" v-else type="text" disabled >收盘成功</el-button>
                </div>
                <div v-else>
                  <el-button class="btn-primary" v-if="scope.row.state == '失败'" type="text" 
                    @click="openingQuotation(scope.row)">强制开盘</el-button>
                  <el-button class="btn-primary" v-else type="text" disabled >开盘成功</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页 -->
        <div class="demo-pagination-block">
          <el-pagination :current-page="data.pageIndex" :page-size="data.pageSize" :page-sizes="[5, 10, 15, 20]"
            layout="total, sizes, prev, pager, next, jumper" :total="data.total" @size-change="sizeChange"
            @current-change="currentChange" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { closingData, forcedClosings, openingQuotations } from './closingMethods'
import { onMounted, reactive } from 'vue'
onMounted(() => {
  closingDatas()

})
let data = reactive({
  valueShare: '',
  movement: '',
  closingInput: '',
  tableData: [],
  state: '',//状态
  pageIndex: 1,//当前页
  pageSize: 10,//每页业条数
  total: 0,//条目总数
  valueOptions: [
    { name: '成功', id: 0, },
    { name: '失败', id: 1, }
  ],
  valueMovement: [
    { name: '开盘', type: 0 },
    { name: '收盘', type: 1 }
  ]
})
// 搜索
let searchFor = () => {
  data.state = data.valueShare
  closingDatas()
}
// 强制收盘按钮
let forcedClosing = (row) => {
  forcedClosings(data, row)
}

// 强制开盘按钮 
let openingQuotation = (row) => {
  openingQuotations(data, row)
}
// 切换页
let closingDatas = () => { closingData(data) }
let sizeChange = (val) => {
  data.pageSize = val
  closingDatas()
}
// 切换查询条数
let currentChange = (val) => {
  data.pageIndex = val
  closingDatas()
}
</script>
<style scoped lang="less">
.process{
  height: calc(100vh - 140px);
  overflow: auto;
  border-radius: 6px;
  background-color: #fff;
  padding:0px 20px;
  padding-top:20px;

}
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}

.demo-pagination-block {
  margin-top: 20px;
  float: right
}
// :deep(.el-card__body){
//   height: calc(100vh - 165px);
// }
</style>